import React from 'react'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo/Seo'
import './PrivacyPolicy.scss'

const Layout = loadable(() => import('../../components/Layout/Layout'))

type PrivacyTypes = {
  data: {
    allPrivacyPolicy: {
      nodes: {
        body: string
      }[]
    }
  }
}

const PrivacyPolicy = ({
  data: {
    allPrivacyPolicy: { nodes: allPrivacyPolicy },
  },
}: PrivacyTypes): JSX.Element => {
  return (
    <Layout>
      <Seo title="Privacy Notice" />
      <div className="privacy-policy-container">
        <div className="privacy-policy">
          <div
            className="privacy-policy-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: allPrivacyPolicy[0]?.body }}
          />
          <StaticImage
            alt="privacy seal"
            src="../../assets/images/seal.png"
            className="privacy-seal-img"
          />
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    allPrivacyPolicy {
      nodes {
        ...PrivacyPolicy
      }
    }
  }
`
